<template>
  <div>
    <div class="title flexB" style="display: block;">
      <h1>{{ $t("top-select-user-manager-dept") }}</h1>
<!--      <div>
        &lt;!&ndash;        <span
        >{{ moment().format("YYYY.MM.DD(dd) HH:mm") }} {{ $t("data-today") }}</span
        >&ndash;&gt;
        <button class="medium" @click="handleRefresh">{{ $t("btn-refresh") }}</button>
      </div>-->
    </div>
    <div class="contents userMgt">
      <div class="box one filter" style="display: flex;flex-direction: row;flex-wrap: wrap;">
        <div class="flex">
          <p class="bold">{{ $t("search-keyword-title") }}</p>
          <select v-model="keywordType">
            <option value="all">{{ $t("search-keyword-all") }}</option>
            <option value="name">{{ $t("department-date-name") }}</option>
            <option value="departmentId">{{ $t("department-date-id") }}</option>
          </select>
          <input
              type="text"
              v-model="keyword"
              @keydown.enter="search"
          />
        </div>
        <div class="flex">
          <p class="bold" style="margin-left: 10px;">{{ $t("search-regdate-title") }}</p>
          <Datepicker
              v-model="startYmd"
              :language="ko"
              :format="customFormatter"
              placeholder="YYYY-MM-DD"
          ></Datepicker>
          <span>~</span>
          <Datepicker
              v-model="endYmd"
              :language="ko"
              :format="customFormatter"
              placeholder="YYYY-MM-DD"
          ></Datepicker>
        </div>
        <div class="flexC">
          <p class="bold">{{ $t("department-date-status") }}</p>
          <label
          ><input
              checked

              type="radio"
              name="status"
              v-model="statuses"
              :value="null"
          />{{ $t("search-keyword-all") }}</label
          >
          <label
          ><input
              type="radio"
              name="status"
              v-model="statuses"
              value="Used"
          />{{ $t("department-date-used") }}</label
          >
          <label
          ><input
              type="radio"
              name="status"
              v-model="statuses"
              value="Stop"
          />{{ $t("department-date-stop") }}</label
          >
        </div>
        <div class="buttonWrap" style="width:100%; margin-top: 15px; display: flex;">
            <button class="point medium" @click="search" style="margin-left: 0px; width: 120px;">
              {{ $t("btn-searching") }}
            </button>
            <button class="medium margin6" @click="reset" style="width: 120px;">
              {{ $t("btn-reset") }}
            </button>
          </div>
      </div>
      <div class="box one">
        <h2 class="bold">
          {{ $t("data-total") }} <span class="blue">{{ total }}</span
        >{{ $t("data-case-ea") }}
          <router-link to="teamRegister"
          ><button class="small right">{{ $t("btn-register") }}</button></router-link
          >
        </h2>
        <table>
          <tr>
            <th>No</th>
            <th>{{ $t("department-date-id") }}</th>
            <th>{{ $t("department-date-name") }}</th>
            <th>{{ $t("department-date-phone") }}</th>
            <th>{{ $t("department-date-addr") }}</th>
            <th>{{ $t("department-date-status") }}</th>
            <th>{{ $t("department-date-regdate") }}</th>
          </tr>
          <colgroup>
            <col style="width: 10%;"/>
            <col style="width: 10%;"/>
            <col style="width: 15%;"/>
            <col style="width: 15%;"/>
            <col style="width: 30%;"/>
            <col style="width: 10%;"/>
            <col style="width: 10%;"/>
          </colgroup>
          <tr v-if="total == 0">
            <td colspan="7" style="text-align: center;">
              {{ $t("search-not-found") }}
            </td>
          </tr>
          <tr v-for="(data, i) in departmentList" :key="i">
            <td>{{ total - (size * currentPage + i) }}</td>
            <td>{{ data.departmentId }}</td>
            <td>
              <a class="link" @click="handleDepartment(data.departmentId)">{{
                  data.name
                }}</a>
            </td>
            <td>{{ getMask(data.phoneNumber) }}</td>
            <td>{{ data.address + " " + data.addressDetail }}</td>
            <td>{{ data.status == "Used" ? $t("department-date-used") : $t("department-date-stop") }}</td>
            <td>
              {{ moment(data.registerDate).format("YYYY.MM.DD HH:mm:ss") }}
            </td>
          </tr>
        </table>
        <div class="pagination">
          <el-pagination
              small
              layout="prev, pager, next"
              :total="total"
              :page-size="10"
              @current-change="handleCurrentChange"
              :current-page="currentPage + 1"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.title{margin-top: 0;}
</style>
<script>
import moment from "moment";
import { ko } from "vuejs-datepicker/dist/locale";
import Datepicker from "vuejs-datepicker";
import { format } from "@/mixins/format";
import { fetchDepartmentList } from "@/api/department";

export default {
  components: { Datepicker },
  mixins: [format],
  name: "TeamList",
  data() {
    return {
      ko: ko,
      moment: moment,
      startYmd: null,
      endYmd: null,
      departmentList: [],
      total: 0,
      size: 10,
      keywordType: "all",
      keyword: null,
      statuses: null,
      currentPage: 0,
      editMode: false,
    };
  },
  created() {
    this.$store.dispatch("store/SET_NAVBAR", { nav: 6, sub: 1 });
  },
  mounted() {
    this.callFunctionsWhenMounted();
  },
  methods: {
    handleDepartment(id) {
      this.$router.push({ path: "teamRegister", query: { departmentId: id } });
    },
    customFormatter(date) {
      return moment(date).format("YYYY년 MM월 DD일");
    },

    handleCurrentChange(val) {
      this.currentPage = val - 1;
      this.getDepartmentList();
    },
    search(){
      if(this.startYmd != null || this.endYmd != null) {
        if (this.startYmd == null || this.endYmd == null || this.startYmd > this.endYmd) {
          return alert(this.$t("alert-message-check-regdate"));
        }
      }
      this.currentPage = 0;
      this.getDepartmentList();
    },
    getDepartmentList() {
      let params = {
        pageNumber: this.currentPage, // pagination
        pageSize: this.size, // pagination
        keywordType: this.keywordType,
        keyword: this.keyword,
        statuses: this.statuses,
      };
      if (this.startYmd != null && this.endYmd != null) {
        let from = this.startYmd ? moment(this.startYmd).format("YYYY-MM-DD") : null;
        let to = this.endYmd ? moment(this.endYmd).format("YYYY-MM-DD") : null;
        params["dateRange.from"] = from;
        params["dateRange.to"] = to;
      }

      fetchDepartmentList(params).then((res) => {
        if (res.data.result == 0) {
          this.total = res.data.data.total;
          this.departmentList = res.data.data.content;
        } else {
          let message = res.data.message;
          alert(message);
        }
      });
    },
    handleRefresh() {
      this.reset();
      this.callFunctionsWhenMounted();
    },
    reset(){
      this.currentPage = 0;
      this.keywordType = "all";
      this.keyword = null;
      this.statuses = null;
      this.startYmd = null;
      this.endYmd = null;
    },
    callFunctionsWhenMounted(){
      this.getDepartmentList();
    }
  },
};
</script>
